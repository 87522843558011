import { createContext, useState, useEffect } from "react";
import {
  getColors,
  // getCategories,
  getSubcategoriesWithCategories,
} from "../api_client/fetch_client";
export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [imageBaseUrl, setImageBaseUrl] = useState(
    "https://pub-dfc05ae5bdf246039b062db57468e72c.r2.dev",
  );
  const [allCategories, setAllCategories] = useState([]);
  const [catToSubcat, setCatToSubcat] = useState(new Map());
  const [subcatToCat, setSubcatToCat] = useState(new Map());
  const [viewCatId, setViewCatId] = useState();
  const [viewSubcatId, setViewSubcatId] = useState();
  const [allColors, setAllColors] = useState([]);
  const [forceReload, setForceReload] = useState(false);
  useEffect(() => {
    const loadImageBaseUrl = async () => {
      if (
        process.env.IMAGE_HOST_BASE_URL === null ||
        process.env.IMAGE_HOST_BASE_URL === undefined
      ) {
        return;
      } else {
        setImageBaseUrl(process.env.IMAGE_HOST_BASE_URL);
      }
    };
    const loadSubcategories = async () => {
      const rsp = await getSubcategoriesWithCategories();
      if (!rsp.success) {
        throw new Error("failed loading getSubcategoriesWithCategories");
      }
      let cats = [];
      let uniqueCats = new Set();
      let cat2subcat = new Map();
      let subcat2cat = new Map();
      for (let i = 0; i < rsp.results.length; i++) {
        if (!uniqueCats.has(rsp.results[i].CAT_ID)) {
          uniqueCats.add(rsp.results[i].CAT_ID);
          cats.push({
            ID: rsp.results[i].CAT_ID,
            NAME: rsp.results[i].CAT_NAME,
            POSITION: rsp.results[i].CAT_POSITION,
          });
        }

        let arr = cat2subcat.get(`${rsp.results[i].CAT_ID}`);
        if (arr === undefined) {
          arr = [];
        }
        arr.push(rsp.results[i]);
        cat2subcat.set(`${rsp.results[i].CAT_ID}`, arr);
        let arr2 = subcat2cat.get(`${rsp.results[i].SUBCAT_ID}`);
        if (arr2 === undefined) {
          arr2 = [];
        }
        arr2.push(rsp.results[i]);
        subcat2cat.set(`${rsp.results[i].SUBCAT_ID}`, arr2);
      }
      // console.log("allCategories", cats);
      // console.log("cat2subcat:", cat2subcat);
      // console.log("subcat2cat:", subcat2cat);
      const sortedCats = cats.sort((a, b) => a.POSITION - b.POSITION);
      setAllCategories(sortedCats);
      setCatToSubcat(cat2subcat);
      setSubcatToCat(subcat2cat);
    };
    const loadColors = async () => {
      const rsp = await getColors();
      if (!rsp.success) {
        throw new Error("failed loading getSubcategoriesWithCategories");
      }
      setAllColors(rsp.results);
    };
    loadImageBaseUrl();
    loadSubcategories();
    loadColors();
  }, [forceReload]);

  const reloadContext = () => {
    setForceReload((p) => !p);
  };

  return (
    <AppContext.Provider
      value={{
        imageBaseUrl,
        setImageBaseUrl,
        allCategories,
        catToSubcat,
        subcatToCat,
        viewCatId,
        setViewCatId,
        viewSubcatId,
        setViewSubcatId,
        reloadContext,
        allColors,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
