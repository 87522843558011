import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  Spacer,
  MenuList,
  MenuItem,
  // MenuDivider,
} from "@chakra-ui/react";
import {
  // FiTrendingUp,
  FiCompass,
  // FiStar,
  // FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import {
  NavLink,
  Outlet,
  Link as RouteLink,
  // redirect,
  useNavigate,
} from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const LinkItems = [
  // { name: "Home", icon: FiHome, path: "/trade" },
  { name: "Price", icon: FiCompass, path: "/trade/price" },
  // { name: "Category", icon: FiCompass, path: "/trade/category" },
  // { name: "SubCategory", icon: FiStar, path: "/trade/subcategory" },
  // { name: "Product", icon: FiSettings, path: "/trade/product" },
  // { name: "Carousel", icon: FiSettings, path: "/trade/carousel" },
];

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box p={2}>
          <a href="/">
            <img src="/img/logo/logo33.png" alt="logo" />
          </a>
        </Box>
        {/*<Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">*/}
        {/*  {" "}*/}
        {/*  [Trade User]*/}
        {/*</Text>*/}
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} path={link.path}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, path, ...rest }) => {
  const activeStyle = {
    bg: "red.400",
    color: "white",
  };
  // console.debug("nav item: ", path, ", rest:", rest);
  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <Box
          as={RouteLink}
          to={path}
          p={1}
          style={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
          // bg={isActive ? "cyan.100" : "red.500"}
          // {...(isActive&&activeStyle)}
        >
          <Flex
            align="center"
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            _hover={{
              bg: "red.400",
              color: "white",
            }}
            {...rest}
            {...(isActive && activeStyle)}
          >
            {icon && (
              <Icon
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: "white",
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        </Box>
      )}
    </NavLink>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { currentTradeUser, updateCurrentTradeUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const signOut = () => {
    updateCurrentTradeUser(null);
    navigate("/");
  };
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo1
      </Text>

      <HStack w={"full"} spacing={{ base: "0", md: "6" }}>
        {/* <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#">Docs</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Breadcrumb</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb> */}
        <Spacer />
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">Trade Customer</Text>
                  <Text fontSize="xs" color="gray.600">
                    {/*{currentUser.USERNAME}*/}
                    {currentTradeUser ? atob(currentTradeUser.USERNAME) : ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              {/*<MenuItem>Profile</MenuItem>*/}
              {/*<MenuItem>Settings</MenuItem>*/}
              {/*<MenuItem>Billing</MenuItem>*/}
              {/*<MenuDivider />*/}
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export const TradePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
};
