import { useState, useEffect, useContext } from "react";
import { getFeature } from "../api_client/fetch_client";
import { AppContext } from "../context/AppContext";

const FeatureItem = ({ IMAGE, NAME, BTN_TEXT, BTN_LINK }) => {
  const { imageBaseUrl } = useContext(AppContext);
  return (
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
      <div class="single-items mb-20">
        <div class="items-img">
          <img src={`${imageBaseUrl}/${IMAGE}`} alt={NAME} />
        </div>
        <div class="items-details">
          <h4>
            <a href={BTN_LINK}>{NAME}</a>
          </h4>
          <a href={BTN_LINK} class="browse-btn">
            {BTN_TEXT}
          </a>
        </div>
      </div>
    </div>
  );
};
export const Feature = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      const rsp = await getFeature();
      if (!rsp.success) {
        console.error("failed to get feature");
      } else {
        setData(rsp.results);
      }
    }
    getData();
  }, []);

  return (
    <section class="items-product1 pt-30">
      <div class="container">
        <div class="row">
          {data.map((item) => (
            <FeatureItem {...item} key={item.ID} />
          ))}
        </div>
      </div>
    </section>
  );
};
