// import { getBackendUrl } from "./server";

export async function getProducts() {
  const response = await fetch(`/api/v1/products`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get products failed");
  }
  return response.json();
}

export async function getCarousel() {
  const response = await fetch(`/api/v1/carousel`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get carousel failed");
  }
  return response.json();
}

export async function getFeature() {
  const response = await fetch(`/api/v1/feature`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get feature failed");
  }
  return response.json();
}

export async function getNewArrivals() {
  const response = await fetch(`/api/v1/new_arrivals`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get newarrivals failed");
  }
  return response.json();
}

export async function getCategories() {
  const response = await fetch(`/api/v1/categories`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get categories failed");
  }
  return response.json();
}

export async function getProductById(prod_id) {
  const response = await fetch(`/api/v1/product/${prod_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get productById failed");
  }
  return response.json();
}

export async function getProductsByCatId(cat_id) {
  const response = await fetch(`/api/v1/products_in_cat/${cat_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.debug("getProductsByCatId(cat_id):", response);
  if (!response.ok) {
    throw new Error("get getProductsByCatId failed");
  }
  return response.json();
}

export async function getProductsBySubcatId(cat_id) {
  const response = await fetch(`/api/v1/products_in_subcat/${cat_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get getProductsBySubcatId failed");
  }
  return response.json();
}

export async function getSubcategoriesWithCategories() {
  const response = await fetch(`/api/v1/subcategories`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("get getSubcategoriesWithCategories failed");
  }
  return response.json();
}

export async function addContact(contactBody) {
  const response = await fetch(`/api/v1/contact_add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contactBody),
  });
  return response.json();
}

export async function getColors() {
  const response = await fetch(`/api/v1/color`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
}

export async function addFreeCutting(body) {
  const response = await fetch(`/api/v1/free_cutting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return response.json();
}
